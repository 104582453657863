@font-face {
    font-family: 'H3GIT Roboto';
    src: url('../../../public/fonts/Roboto-Regular.ttf');
}

@font-face {
    font-family: 'H3GIT Roboto Bold';
    src: url('../../../public/fonts/Roboto-Regular.ttf');
}

@font-face {
    font-family: 'H3GIT Roboto Italic';
    src: url('../../../public/fonts/Roboto-Regular.ttf');
}

@font-face {
    font-family: 'H3GIT Roboto Bold Italic';
    src: url('../../../public/fonts/Roboto-Regular.ttf');
}

:root {
    --font-name: 'H3GIT Roboto', 'Roboto';
    --font-name-bold: 'H3GIT Roboto Bold', 'Roboto';
    --font-name-italic: 'H3GIT Roboto Italic', 'Roboto';
    --font-name-bold-italic: 'H3GIT Roboto Bold Italic', 'Roboto';

    --font-size-base: 1rem;
    --font-size-sm: 1.1rem;
    --font-size-lg: 1.5rem;
    --font-size-jumbo: 8em;

    --font-size-tiny: 8px;
    --font-size-small: 9px;
    --font-size-xxs: 10px;
    --font-size-xs: 11px;
    --font-size-small-text: 12px;
    --font-size-caption: 13px;
    --font-size-body-sm: 14px;
    --font-size-compact: 15px;
    --font-size-body: 16px;
    --font-size-md: 17px;
    --font-size-body-lg: 16px; /* styles as per figma for h3g */
    --font-size-body-xl: 20px; /* styles as per figma for h3g */
    --font-size-subtitle: 20px;
    --font-size-subtitle-md: 16px; /* styles as per figma for h3g */
    --font-size-subtitle-lg: 22px;
    --font-size-heading-sm: 16px; /* styles as per figma for h3g */
    --font-size-heading: 24px;
    --font-size-heading-alt: 25px;
    --font-size-heading-md: 26px;
    --font-size-heading-lg: 28px;
    --font-size-title-sm: 30px;
    --font-size-title-sm-alt: 31px;
    --font-size-title: 34px; /* styles as per figma for h3g */
    --font-size-title-alt: 33px;
    --font-size-title-md: 34px;
    --font-size-title-lg: 36px;
    --font-size-title-xl: 38px;
    --font-size-display-sm: 42px;
    --font-size-display: 48px;
    --font-size-display-lg: 60px;

    --font-size-hero-action-btn: 16px;
    --font-size-hero-action-btn-md: 16px;
    --font-size-product-price: 13.5px;
}
